<template src="./productDetails.html"></template>

<script>
import GlobalProductDetail from '@/mixins/product-detail';
import header from '../header/header';

export default {
  name: 'RecommendationMachineProductDetail',
  components: {
    recommendationHeader: header,
  },
  mixins: [GlobalProductDetail],
  computed: {
    productId() {
      return this.$route.query.sociolla_id;
    },
  },
};
</script>

<style lang="scss">
@import 'productDetails';
</style>
