import ProductDisplay from '@/components/global/product-display/product-display';
import Reviews from '@/components/global/reviews/reviews';

export default {
  name: 'GlobalProductDetail',
  components: {
    ProductDisplay,
    Reviews,
  },
  data() {
    return {
      isAttributeNotChange: true,
    };
  },
  computed: {
    activeAttribute() {
      return this.$store.state.productDetail.data.activeAttribute;
    },
    auth() {
      return this.$store.state.auth;
    },
    groupActiveProductAttribute() {
      let attributeIdMerge = '';

      if (this.isAttributeNotChange && this.$SO.size(this.product.default_product_attribute)) {
        this.$SO.each(this.product.default_product_attribute.attribute, (key, obj) => {
          attributeIdMerge += obj.id_attribute;
        });
      } else {
        this.$SO.each(this.activeAttribute, (prop, val) => {
          attributeIdMerge += val;
        });
      }

      return attributeIdMerge;
    },
    product() {
      return this.$store.state.productDetail.data.product;
    },
    productDetail() {
      return this.$store.state.productDetail;
    },
    reviewsProduct() {
      return this.$store.state.productDetail.data.reviews;
    },
    totalReview() {
      return this.$store.state.productDetail.data.totalReview;
    },
  },
  watch: {
    activeAttribute() {
      if (this.$SO.size(this.activeAttribute)) {
        this.isAttributeNotChange = false;
      }
    },
    product() {
      if (this.productDetail.data.product) {
        // this.$store.dispatch('getReviewsProduct')
        this.$store.dispatch('getReviewsProductV2');
        this.$store.dispatch('generatedArrayProductAttribute');
      }
    },
  },
  created() {
    this.$store.commit('setDataOnProductDetail', { stateName: 'reviews', newVal: [] });
    this.$store.commit('setDataOnProductDetail', { stateName: 'product', newVal: {} });
    this.$store.dispatch('getProductDetail', { productId: this.productId, pageName: this.pageName });
  },
};
